










import { Component, Prop, Vue } from 'vue-property-decorator';

type Larold = {
  name: string;
  path: string;
  author: string;
};

@Component
export default class LaroldImg extends Vue {
  @Prop(String) name?: string;

  private larolds: Larold[] = [
    {
      name: 'rad larold',
      path: '/static/hand-picked-larolds/rad-larold.png',
      author: '@katsaii',
    },
    {
      name: 'headphone larold',
      path: '/static/hand-picked-larolds/headphone-larold.png',
      author: '@baku',
    },
    {
      name: 'artist larold',
      path: '/static/hand-picked-larolds/artist-larold.png',
      author: '@baku',
    },
    {
      name: 'helicopter larold',
      path: '/static/hand-picked-larolds/helicopter-larold.png',
      author: '@baku',
    },
    {
      name: 'ghost larold',
      path: '/static/hand-picked-larolds/ghost-larold.png',
      author: '@baku',
    },
    {
      name: 'awesome larold',
      path: '/static/hand-picked-larolds/awesome-larold.png',
      author: '@nahoo',
    },
    {
      name: 'bunny larold',
      path: '/static/hand-picked-larolds/bunny-larold.png',
      author: '@masharcade',
    },
    {
      name: 'frog larold',
      path: '/static/hand-picked-larolds/frog-larold.png',
      author: '@baku',
    },
  ];

  private get alt() {
    if (!this.larold) {
      return '';
    }
    return this.larold
      ? `${this.larold.name} by ${this.larold.author}`
      : 'beewut';
  }

  private get src() {
    return this.larold?.path || '';
  }

  private get larold() {
    const index = this.larolds.findIndex((i) => i.name == this.safeName);
    if (index < 0) {
      return undefined;
    }
    return this.larolds[index];
  }

  private get safeName() {
    return this.name || 'rad larold';
  }
}
