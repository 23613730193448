

































import LaroldImg from '@/components/LaroldImg.vue';
import GameCollection, { ViewType } from './components/GameColletion.vue';
import { Component, Vue } from 'vue-property-decorator';
import { getRouteMetadata, RoutePath } from '../../common';

@Component({
  components: {
    LaroldImg,
    GameCollection,
  },
  metaInfo() {
    const routeMetadata = getRouteMetadata(
      this.$route.matched[this.$route.matched.length - 1].path as RoutePath,
      this.$route.params,
      this.$route.query
    );
    return {
      title: routeMetadata.title,
    };
  },
})
export default class Browse extends Vue {
  private viewType: ViewType =
    (localStorage.getItem('browse.viewType') as ViewType) || 'list';

  private get games() {
    return this.$lwMeta.games.sort((a, b) => {
      return a.displayName[0] > b.displayName[0] ? 1 : -1;
    });
  }

  private getBtnClass(type: ViewType) {
    if (type === this.viewType) {
      return 'btn solid red';
    }
    return 'btn';
  }

  private setViewType(type: ViewType) {
    this.viewType = type;
    localStorage.setItem('browse.viewType', this.viewType);
  }
}
