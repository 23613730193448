






































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Cart extends Vue {
  @Prop(String) gameId?: string;
  @Prop(Number) size?: number;

  private get game() {
    return this.$lwMeta.findGame(this.gameId || '');
  }

  private get primaryColor() {
    return this.game?.colorPrimary || '#ff0000';
  }

  private get secondaryColor() {
    return this.game?.colorSecondary || '#ff0000';
  }

  private get label() {
    return `/static/games/${this.gameId}.png`;
  }

  private get width() {
    return 174 * (this.size || 1);
  }

  private get height() {
    return 152 * (this.size || 1);
  }

  private get labelWidth() {
    return 152 * (this.size || 1);
  }

  private get labelHeight() {
    return 72 * (this.size || 1);
  }

  private get labelX() {
    return 12 * (this.size || 1);
  }

  private get labelY() {
    return 66 * (this.size || 1);
  }
}
