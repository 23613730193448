




























































import Cart from '@/components/Cart.vue';
import Contributor from '@/components/Contributor.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { MicrogameMetadata, routeName } from '@/common';
import gql from 'graphql-tag';
import * as schema from '@/gql/schema';

export type ViewType = 'grid' | 'list' | 'carts';

@Component({
  components: {
    Cart,
    Contributor,
  },
  apollo: {
    microgameByGameId: {
      skip() {
        return !this.game;
      },
      variables() {
        return {
          gameId: this.game.id,
        };
      },
      query: gql`
        query GameCollectionCard_microgame($gameId: String!) {
          microgameByGameId(gameId: $gameId) {
            id
            communityRating {
              difficulty
              favorited
              total
            }
          }
        }
      `,
    },
  },
})
export default class GameCollectionCard extends Vue {
  @Prop(String) viewType!: ViewType;
  @Prop(Object) game!: MicrogameMetadata;

  private microgameByGameId!: schema.Microgame;

  private get microgame() {
    return this.microgameByGameId;
  }

  private get cartSize() {
    switch (this.viewType) {
      case 'list':
        return 0.5;
      case 'grid':
        return 1;
      case 'carts':
        return 1;
    }
    return 1;
  }

  private getRoute(gameId: string) {
    return {
      name: routeName('game-page'),
      params: {
        game: gameId,
      },
    };
  }
}
