























import LaroldImg from '@/components/LaroldImg.vue';
import GameCollectionCard from './GameCollectionCard.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { MicrogameMetadata, routeName } from '@/common';
export type ViewType = 'grid' | 'list' | 'carts';

@Component({
  components: {
    LaroldImg,
    GameCollectionCard,
  },
})
export default class GameCollection extends Vue {
  @Prop(Array) games!: MicrogameMetadata[];
  @Prop(String) viewType!: ViewType;

  private get cartSize() {
    switch (this.viewType) {
      case 'list':
        return 0.5;
      case 'grid':
        return 1;
      case 'carts':
        return 1;
    }
    return 1;
  }

  private getRoute(authorSlug: string, gameSlug: string) {
    return {
      name: routeName('game-page'),
      params: {
        author: authorSlug,
        game: gameSlug,
      },
    };
  }
}
